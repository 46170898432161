'use client';

import { FontAwesomeIconProps, FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useId } from 'react';

/**
 * Wrapped class for icons
 *
 * There are issues with using FontAwesome + Next.js directly, so this is wrapping tags with NoSSR:
 * https://github.com/FortAwesome/Font-Awesome/issues/19348
 *
 * @param props
 * @returns
 */
export function Icon(props: FontAwesomeIconProps): ReactNode {
    const titleId = useId();

    let title = null;
    const ariaProps = {};
    if (props.title) {
        title = <span className="sr-only">{props.title}</span>;
        ariaProps['title'] = undefined;
        ariaProps['aria-labelledby'] = titleId;
    }

    return (
        <>
            {title}
            <FontAwesomeIcon {...props} {...ariaProps} />
        </>
    );
}
