'use client';

import { useAuthenticatedJsonGet } from '@/common/util/services/useAuthenticatedJsonGet';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import LoadingContent from '../loading/template';
import CommonError from '@/components/error/page';

export interface AuthenticatedJsonContentProps extends PropsWithChildren {
    url?: URL | string;
    setData?: (data: unknown) => void;
    setLoading?: (loading: boolean) => void;
    loadingContent?: React.ReactNode;
    noDataContent?: React.ReactNode;
    hasData?: boolean;
    default?: unknown;
}

interface DataLoadingProps {
    message?: string;
}

function DataLoadingError(props: DataLoadingProps): ReactNode {
    const message = props.message || 'Error loading data, click to retry';
    return <>{message}</>;
}

export default function AuthenticatedJsonContent(props: AuthenticatedJsonContentProps): ReactNode {
    const url = props.url;
    const setData = props.setData;
    const setLoading = props.setLoading;
    const defaultValue = props.default;

    const [results, loading, error, mutate] = useAuthenticatedJsonGet(url);

    useEffect(() => {
        // Skip if we don't have an external function to update
        if (!setLoading) {
            return;
        }

        setLoading(loading);
    }, [loading, setLoading]);

    useEffect(() => {
        // Skip if we don't have an external function to update
        if (!setData) {
            return;
        }

        // Don't update data until we're done loading
        if (loading) {
            return;
        }

        let newResults = defaultValue;
        if (results !== undefined) {
            newResults = results;
        }

        setData(newResults);
    }, [results, loading, setData, defaultValue]);

    if (error) {
        return <CommonError error={error} reset={mutate} tooltipContent={<DataLoadingError />} />;
    }

    return (
        <LoadingContent
            loading={loading}
            data={results}
            noDataContent={props.noDataContent}
            hasData={props.hasData}
            loadingContent={props.loadingContent}
        >
            {props.children}
        </LoadingContent>
    );
}
