import { getURL } from '../common/util/network/url/getURL';

/**
 * Hook for keeping track of a URL
 *
 * Ensure that the URL object only updates when the resulting string changes
 *
 * @param url
 * @returns
 */
export function useURL(url: URL | string): string {
    // Corece to a string to prevent downstrem components from re-rendering when values change
    const newUrl = getURL(url);
    return newUrl?.toString();
}
