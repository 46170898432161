import { useGetToken } from '@/contexts/TokenContext/useToken';
import useSWR, { SWRConfiguration } from 'swr';
import { authenticatedFetch } from './authenticatedFetch';
import { useURL } from '@/hooks/useURL';
import { minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';
import { useCallback } from 'react';
import { ErrorResponse } from '@/common/interfaces/Error';
import { logError } from '../logging/writeLog';
import { useAppInsights } from '@/components/AppInsightsWrapper/AppInsightsWrapper';

const swrOptions: SWRConfiguration = {
    // Don't try to grab again on focus for at least 5 minutes
    focusThrottleInterval: minutesToMilliseconds(5),
    // If multiple requests come in within the same 15 seconds, make sure to group them together
    dedupingInterval: secondsToMilliseconds(15),
    // How long to trigger slow loading indicator
    loadingTimeout: secondsToMilliseconds(3),
};

export function useAuthenticatedJsonGet<T>(inputUrl: URL | string): [T, boolean, Error, () => void] {
    const appInsights = useAppInsights();

    // Use a state so that we don't trigger updates unnecessarily
    const url = useURL(inputUrl);
    const getToken = useGetToken();

    const fetcher = useCallback(async () => {
        const response = await authenticatedFetch<T>(appInsights, getToken, url);

        // TODO: if this is an error we need to detect and handle it better
        const errorResponse = response as ErrorResponse;
        if (errorResponse?.error) {
            // Log what the error was
            logError(appInsights, 'Unable to fetch via GET: ', { url: url });

            // Rethrow error so that SWR can pass it on to clients
            throw new Error(errorResponse.error.message);
        }

        return response as T;
    }, [appInsights, getToken, url]);

    const { data, mutate, error, isLoading } = useSWR<T>(url?.toString(), fetcher, swrOptions);

    return [data, isLoading, error as Error, mutate];
}
