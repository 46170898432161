import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { logError, logException } from '../logging/writeLog';
import { normalizeValue } from '../strings/normalizeValue';

export async function getResponseBody<T>(appInsights: ReactPlugin, response: Response): Promise<T> {
    // Shortcut if we didn't have a response object
    if (!response) {
        return undefined;
    }

    // Clone the response first to avoid errors if we try to read it more than once
    const clonedResponse = response.clone();

    let body: T = undefined;
    let text: string = undefined;
    try {
        // Get as text first in case the server responded with something that wasn't JSON
        text = await clonedResponse.text();
        text = normalizeValue(text);

        // Attempt to parse
        if (text !== undefined) {
            const responseBody = JSON.parse(text);
            body = responseBody as T;
        }
    } catch (error) {
        logError(appInsights, 'Unable to process response', { text: text });
        logException(appInsights, error);
    }

    return body;
}
