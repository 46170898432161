import { ignoreBlankValues } from './ignoreBlankValues';

function getObjectValue(_key: string, value: object): object {
    const keys = Object.keys(value);
    keys.sort();

    const sortedObject = keys.reduce((sorted, key) => {
        const keyValue = value[key];

        // Ignore functions
        if (typeof keyValue !== 'function') {
            sorted[key] = keyValue;
        }

        return sorted;
    }, {});

    return sortedObject;
}

export function getStableJson(_key: string, value: unknown): unknown {
    // Ensure keys are sorted in objects
    if (value instanceof Object && !(value instanceof Array)) {
        return getObjectValue(_key, value);
    }

    return ignoreBlankValues(_key, value);
}
