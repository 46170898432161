import { getStableJson } from './getStableJson';

// TODO: better enforce typing to ensure that we're passing valid JSON

export function stringifyJSON(value: unknown): string {
    try {
        const jsonString = JSON.stringify(value, getStableJson, 4);
        return jsonString;
    } catch {
        return undefined;
    }
}
