import { useCallback } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { fetchToken } from './useAuthenticationResult';
import { useAppInsights } from '@/components/AppInsightsWrapper/AppInsightsWrapper';

export function useGetToken(): () => Promise<string> {
    const appInsights = useAppInsights();
    const { accounts, instance } = useMsal();
    const account = useAccount(accounts?.[0] || null);

    const getToken = useCallback(async () => {
        return fetchToken(appInsights, instance, account);
    }, [instance, account, appInsights]);

    return getToken;
}
