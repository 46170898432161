import { normalizeValue } from '@/common/util/strings/normalizeValue';

export function getURL(url: URL | string): URL {
    if (!url) {
        return undefined;
    }

    // Already a URL
    if (url instanceof URL) {
        return url;
    }

    return getURLFromString(url);
}

export function getURLFromString(url: string): URL {
    const normalized = normalizeValue(url);
    if (!normalized) {
        return undefined;
    }

    try {
        return new URL(normalized);
    } catch {
        console.warn('Error getting URL: ', normalized);
        return undefined;
    }
}

export function getURLPath(url: URL | string): string {
    const urlObject = getURL(url);
    return urlObject?.pathname;
}
