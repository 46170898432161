import { normalizeValue } from '../strings/normalizeValue';

export function ignoreBlankValues(_key: string, rawValue: unknown): unknown {
    let value = rawValue;

    // Normalize strings
    if (typeof value === 'string') {
        value = normalizeValue(value);
    }

    // Ignore anything undefined
    if (value === null || value === undefined || value === '') {
        return undefined;
    }

    return value;
}
